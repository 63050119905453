import { groupBy, range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, columns, groupIndex) {

  const groupTitle = groups?.component.name;
  const groupCode = groups?.component.number;
  const data = groups?.data;
  if (data?.length) {
    return [
      {
        rowId: `${groupTitle}-${groupIndex}-Header`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(`${groupCode}`, "light-green-bg font-bold"),
          headerCell(`${groupTitle}`, "light-green-bg font-bold", columns.length),
        ],
      },
      ...data.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-${groupIndex}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(title, "total-row"),
          ...range(0, columns.length - 5).map(() => headerCell("", "total-row")),
          headerCell(formatCurrency(totalDebit), "total-row-debit"),
          headerCell(formatCurrency(totalCredit), "total-row-credit"),
          ...range(7, columns.length - 7).map(() => headerCell("", "total-row")),
        ],
      },
    ];
  }
  return [];
}

function getGrandTotalRow(title,
  totalDebit, totalCredit, columns) {

  return {
    rowId: `${title}Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "light-green-bg font-bold"),
      ...range(0, columns.length - 5).map(() => headerCell("", "light-green-bg")),
      headerCell(formatCurrency(totalDebit), "total-row-debit"),
      headerCell(formatCurrency(totalCredit), "total-row-credit"),
      ...range(7, columns.length - 7).map(() => headerCell("", "light-green-bg")),

    ],
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getTrialBalanceComponentRows({ data, columns }) {
  let grandTotalCredit = 0;
  let grandTotalDebit = 0;
  if (data?.data.length < 1) {
    return [];
  }

  const groups = groupBy(data?.data, "component");
  const groupValues = Object.values(groups);
  if (groupValues.length < 1) {
    return;
  }
  // Create group rows
  const groupedRows = groupValues[0]?.flatMap((group, index) => {
    group.data = group.data.map((item) => ({
      ...item,
      componentNumber: group.component.number
    }));
    grandTotalCredit += Number(group.totalCredits);
    grandTotalDebit += Number(group.totalDebits);
    return getGroupRows("Total", group, group.totalDebits, group.totalCredits, columns, index);
  });
  return [
    getHeaderRow(columns),
    ...groupedRows,
    getGrandTotalRow(
      "Grand Total",
      grandTotalDebit,
      grandTotalCredit,
      columns
    )
  ];
}
