import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import {
  TwoColumnList, BoxedContent, Header, SvgIcon, IconButton, HeaderLeftContent
} from "@/components/common";
import { formatDecimalValues, formatDate } from "@/utils/helpers";

import {
  getReceiptColumns, getReceiptRows, getTenantStatementRows, getTenantStatementColumns
} from "./tenant-statement";
import { getTenantStatementInvoices, getTenantStatement } from "@/api/finance/reportApi";
import { renderDynamicFieldValue } from "@/utils/dynamic/helpers";
import {
  DynamicReport
} from "@/components/dynamic";
import { selectActiveCompany } from "@/store/appSlice";
import appSettings from "@/settings";

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

function ReportTenantStatement() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const params = useParams();
  const tenant = params?.id;
  const [tenantStatement, setTenantStatement] = useState({
    rent: [],
    deposit: [],
    other: []
  });

  const exportExcel = (id) => window.open(`${appSettings.baseUrl}/leasing/reports/tenant/${id}/xls?companyId=${activeCompany.id}`, "_blank", "noopener,noreferrer");

  const tenantStatementFilters = {
    TenantId: tenant,
  };

  const { data: tenantStatementData } = useQuery(["tenant-statement"],
    () => getTenantStatementInvoices({ TenantId: tenant }),
    {
      enabled: Boolean(tenant),
    });

  const { data: contractDataApi } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), tenant],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ContractObjectName")), {
        tenant,
        takePage: 1,
        limitPage: 1,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(tenant),
    }
  );

  const contractData = useMemo(() => (contractDataApi?.data?.length > 0 ?
    contractDataApi.data[0] : []), [contractDataApi]);

  useEffect(() => {
    if (tenantStatementData) {
      let tenantStatementDataTemp = tenantStatementData.data;
      const dummyData = {
        rent: [],
        deposit: [],
        other: []
      };

      if (filterText) {
        tenantStatementDataTemp = tenantStatementData.data.filter(
          (obj) => obj.invoiceNumber.toLowerCase()
            .includes(filterText.toLowerCase()));
      }

      tenantStatementDataTemp?.forEach((item) => {
        const category = item.category.toLowerCase();
        dummyData[category].push({
          description: item.memo || "Invoice",
          invoiceNo: item.invoiceNumber,
          amount: item?.amount ? formatDecimalValues(parseFloat(item.amount)) : "",
          vat: item?.vAT ? formatDecimalValues(parseFloat(item.vAT)) : "",
          total: item?.total ? formatDecimalValues(parseFloat(item.total)) : "",
          paidAdjusted: "",
          balance: item?.total ? formatDecimalValues(parseFloat(item.total)) : ""
        });
      });
      setTenantStatement(dummyData);
    }
  }, [tenantStatementData, filterText]);

  const columns = useMemo(
    () => getTenantStatementColumns(ref?.current?.clientWidth),
    [ref?.current?.clientWidth]
  );

  const rows = useMemo(
    () =>
      getTenantStatementRows({
        data: {
          data: tenantStatement,
        },
        columns,
      }),
    [columns, tenantStatement, filterText]
  );

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Tenant Statement" icon="file-check-stroke-icon" />}
        rightContent={(
          <div>
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel(tenant)}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
          </div>
        )}
      />
      <>
        <DetailItem>
          <div className="card">
            <div className="row">
              <div className="col">
                <TwoColumnList
                  data={[
                    {
                      label: "Contract No",
                      value: contractData?.number,
                      render: renderDynamicFieldValue({
                        fieldType: "Lookup",
                        fieldName: "contract",
                        label: contractData?.number,
                        value: contractData?.number,
                        id: contractData?.id,
                      }
                      )
                    },
                    {
                      label: "Tenant Name",
                      value: contractData?.tenant?.name,
                    },
                    {
                      label: "Tenant No.",
                      render: renderDynamicFieldValue({
                        fieldType: "Lookup",
                        fieldName: "tenant",
                        label: contractData?.tenant?.number,
                        value: contractData?.tenant?.number,
                        id: contractData?.tenant?.id,
                      })
                    },
                  ]}
                />
              </div>
              <div className="col">
                <TwoColumnList
                  data={[
                    {
                      label: "Agreement Start Date",
                      value: contractData?.agreementStartDate ? formatDate(new Date(contractData?.agreementStartDate)) : "",
                    },
                    {
                      label: "Agreement End Date",
                      value: contractData?.agreementEndDate ? formatDate(new Date(contractData?.agreementEndDate)) : "",
                    },
                    {
                      label: "Total Contract Amount",
                      value: contractData?.contractAmount,
                    },
                  ]}
                />
              </div>
              <div className="col">
                <TwoColumnList
                  data={[
                    {
                      label: "Building",
                      render: renderDynamicFieldValue({
                        fieldType: "Lookup",
                        fieldName: "building",
                        label: contractData?.building?.number,
                        value: contractData?.building?.number,
                        id: contractData?.building?.id,
                      })
                    },
                    {
                      label: "Component",
                      value: contractData?.component?.name,
                    },
                    {
                      label: "Unit No.",
                      render: renderDynamicFieldValue({
                        fieldType: "Lookup",
                        fieldName: "unit",
                        allowMultiple: true,
                        label: contractData?.unit,
                        values: contractData?.unit?.map((item) => ({
                          label: item.name,
                          value: item.id
                        })) || [],
                        id: contractData?.unit?.id,
                      })
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </DetailItem>
        <div className="table-wrapper report-sheet" ref={ref}>
          <div className="row filter-row">
            <div className="col-md-3">
              <Input
                className="input-height-fix"
                value={filterText}
                onChange={setFilterText}
                placeholder="Search by name"
              />
            </div>
          </div>
          <div className="react-grid-container">
            <ReactGrid
              rows={rows}
              columns={columns}
              stickyTopRows={1}
              enableFillHandle
              enableRangeSelection
            />
          </div>
        </div>
        <DynamicReport
          reportName="tenant-statement"
          activeCompany={activeCompany}
          columnsDataProvider={getReceiptColumns}
          rowsDataProvider={getReceiptRows}
          fetchData={getTenantStatement}
          exportUrl="reports/export-query-report/tenant-statement"
          filtersInitialState={tenantStatementFilters}
          orderBy="ReferenceNumber"
        />
      </>
    </BoxedContent>

  );
}

export default ReportTenantStatement;
