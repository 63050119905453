import qs from "qs";

import request from "@/utils/api/helpers";

const baseUrl = "/finance/reports";
const reportUrl = "/reports/query-report";
const pagedReportUrl = "/reports/paged-query-report";

const getGeneralJournal = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/general-journal?${queryParams}`
  });
};

export const getJournalLedger = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/journal-ledger?${queryParams}`
  });
};

export const getTrialBalance = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/trial-balance?${queryParams}`
  });
};

export const getBalanceSheet = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/balance-sheet?${queryParams}`,
    headers: {
      Companyid: params?.companyId
    }
  });
};

export const getCashFlow = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/cash-flow?${queryParams}`,
    headers: {
      Companyid: params?.companyId
    }
  });
};

export const getProfitLoss = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/profit-and-loss?${queryParams}`
  });
};

export const getVatDetails = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `/reports/paged-query-report/vat-details?${queryParams}`
  });
};

export const getVATExceptions = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/vat-exceptions?${queryParams}`
  });
};

export const getVatReconciliation = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/vat-reconciliation?${queryParams}`
  });
};

export const getBuildingCost = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/building-cost?${queryParams}`
  });
};

export const getBuildingRevenue = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/building-revenue?${queryParams}`
  });
};

export const getRentRoll = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/rent-roll?${queryParams}`
  });
};

export const getRentRollArchive = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/rent-roll-archive?${queryParams}`
  });
};

export const getOutOfScope = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({ url: `${pagedReportUrl}/out-of-scope?${queryParams}` });
};

export const getStandardRatedSales = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/standard-rate-sale?${queryParams}`
  });
};

export const getExemptSuppliesSale = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/exempt-sale?${queryParams}`
  });
};

export const getExemptSuppliesPurchase = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/exempt-purchase?${queryParams}`
  });
};

export const getStandardRatedPurchase = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/standard-rate-purchase?${queryParams}`
  });
};

export const getZeroRatedPurchase = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({ url: `${pagedReportUrl}/zero-rate-purchase?${queryParams}` });
};

export const getZeroRatedSales = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/zero-rate-sale?${queryParams}`
  });
};

export const getTenantOutstanding = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/tenant-outstanding?${queryParams}`
  });
};

export const getInvoiceListing = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/invoice-listing?${queryParams}`
  });
};

export const getSupplierAdvancePayment = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/suppliers-advance-payments-with-expense?${queryParams}`
  });
};

export const getVatInputApportionment = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/vat-input-apportionment?${queryParams}`
  });
};

export const getBlanketAgreementDetail = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/blanket-agreement?${queryParams}`
  });
};

export const getDailyTransactionDetail = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/daily-transaction-detail?${queryParams}`
  });
};

export const getPlannedActualPpmInhouse = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/ppm-report-internal?${queryParams}`
  });
};

export const getPlannedActualPpmExternal = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/ppm-report-external?${queryParams}`
  });
};

export const getOccupancyReport = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/occupancy?${queryParams}`
  });
};

export const getTaxLiability = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/tax-liability?${queryParams}`
  });
};

export const getForthComingRenewals = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/forthcoming-renewals?${queryParams}`
  });
};

export const getCustomerAging = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/customer-aging?${queryParams}`
  });
};

export const getVacancyAging = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/vacancy-aging?${queryParams}`
  });
};

export const getForthcomingVacancy = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/forthcoming-vacancy?${queryParams}`
  });
};

export const getUnitReady = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/unit-ready?${queryParams}`
  });
};

export const getUtilityContractReport = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/utility-contract?${queryParams}`
  });
};

export const getBankAccountStatement = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/bank-account-statement?${queryParams}`
  });
};

export const getTenantStatementInvoices = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/tenant-statement-invoices?${queryParams}`
  });
};

export const getTenantStatement = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/tenant-statement?${queryParams}`
  });
};

export const getSupplierAging = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/supplier-aging-report?${queryParams}`
  });
};

export const getSupplierPayment = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/supplier-payment?${queryParams}`
  });
};

export const getConsolidatedSupplierAging = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/consolidated-supplier-aging-report?${queryParams}`
  });
};

export const getSupplierOpenTransaction = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/supplier-open-transactions?${queryParams}`
  });
};

export const getAdvancePayment = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/supplier-advance-payments?${queryParams}`
  });
};

export const getPaidInvoices = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/supplier-paid-invoices?${queryParams}`
  });
};

export const getFARReport = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/fixed-assets-register?${queryParams}`
  });
};

export const getVATSummaryReport = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/vat-summary?${queryParams}`
  });
};

export const getWorkOrderAgingReport = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/work-order-aging?${queryParams}`
  });
};

export const getBounceCheque = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/bounce-cheque?${queryParams}`
  });
};

export const getExpenseDetails = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/expense-details?${queryParams}`
  });
};

export const getClearCheque = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/clear-cheque?${queryParams}`
  });
};

export const getCollectCheque = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/collect-cheque?${queryParams}`
  });
};

export const getOutputVat = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/vat-output?${queryParams}`
  });
};

export const getInputVat = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${reportUrl}/vat-input?${queryParams}`
  });
};

export const getTrialBalanceComponent = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/trial-balance/components?${queryParams}`
  });
};

export const getOpex = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${baseUrl}/opex?${queryParams}`
  });
};

export const getCollection = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/collection-report?${queryParams}`
  });
};

export const getPettyCashByBuilding = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/petty-cash-claim-by-building?${queryParams}`
  });
};

export const getPettyCashBySupervisor = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/petty-cash-claim-by-supervisor?${queryParams}`
  });
};

export const getPettyCashClaim = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/petty-cash-claim-categories?${queryParams}`
  });
};

export const getContractSecurityDeposit = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/contract-security-deposit?${queryParams}`
  });
};

export const getRevenueRecognitionDetail = (params = {}) => {
  const queryParams = qs.stringify(params);
  return request({
    url: `${pagedReportUrl}/revenue-recognition-details?${queryParams}`
  });
};

export default getGeneralJournal;
