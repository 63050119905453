import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getAgingColumns() {

  const colArr = [
    {
      label: "Contract",
      value: true,
      columnId: "contract",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Installments",
      value: true,
      columnId: "installments",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Amount",
      value: true,
      columnId: "amount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Due Date",
      value: true,
      columnId: "dueDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : ""),
    },
    {
      label: "Transaction Date",
      value: true,
      columnId: "transactionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : ""),
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Tenant Number",
      value: true,
      columnId: "tenantNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Tenant Name",
      value: true,
      columnId: "tenantName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Building Number",
      value: true,
      columnId: "buildingNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Building Name",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Payment Status",
      value: true,
      columnId: "paymentStatus",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Balance",
      value: true,
      columnId: "balance",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Paid",
      value: true,
      columnId: "paid",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Not Due",
      value: true,
      columnId: "notDue",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "0-30 Days",
      value: true,
      columnId: "oneMonth",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "31-60 days",
      value: true,
      columnId: "twoMonth",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "61-90 days",
      value: true,
      columnId: "threeMonth",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "91 - 121 Days",
      value: true,
      columnId: "over91Days",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "122 - 365 Days",
      value: true,
      columnId: "over122Days",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Above 365 Days",
      value: true,
      columnId: "over365Days",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "PDC Number",
      value: true,
      columnId: "pdcNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "PDC Amount",
      value: true,
      columnId: "pdcAmount",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "PDC Status",
      value: true,
      columnId: "pdcStatus",
      width: estimatedColumnWidth("status"),
      resizable: true,
      formatter: (value) => String(value || ""),
    }
  ];

  return colArr;
}
