import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase, startCase } from "lodash";

import { useSearchParams } from "react-router-dom";
import { calculateTax } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecords, getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getSupplierQuotationTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import useTaskRedirect from "@/hooks/useTaskRedirect";

const formatQuotation = (quotation, taxRule) => {
  const { itemType } = quotation;
  const isFixedAsset = itemType === "Asset";
  const data = {
    key: quotation.id,
    quotation: {
      label: quotation.number,
      value: quotation.id,
    },
    requestForQuotation: quotation?.requestForQuotation,
    itemDetail: quotation?.itemDetail?.map((item) => {
      const itemData = {
        quantity: item.quantity,
        rate: item.rate,
        amount: item.quantity * item.rate,
      };
      if (taxRule) {
        itemData.tax = {
          label: taxRule.name,
          value: taxRule.id,
          purchaseRate: taxRule?.purchaseRate,
          saleRate: taxRule?.saleRate,
        };
      }
      const taxAmount = (itemData.amount * (taxRule?.purchaseRate ?? 0)) / 100;
      const totalAmount = itemData.amount + taxAmount;

      itemData.taxAmount = taxAmount;
      itemData.totalAmount = totalAmount;

      if (isFixedAsset) {
        itemData.label = item.label;
      } else {
        itemData.item = {
          label: item.item?.itemName,
          value: item.item?.id,
        };
      }

      return itemData;
    }),
    discount: quotation.discount,
    discountType: {
      label: startCase(quotation.discountType),
      value: quotation.discountType,
    },
    isSelected: false,
    itemType: {
      label: itemType,
      value: itemType,
    }
  };

  if (quotation?.supplier && JSON.stringify(quotation?.supplier) !== "{}") {
    data.supplier = {
      ...quotation?.supplier,
      label: quotation?.supplier?.name,
      value: quotation?.supplier?.id
    };
  }
  data.subtotal = data.itemDetail.reduce(
    (prevValue, currValue) => Number(prevValue) + Number(currValue.totalAmount),
    0
  );

  data.total = (data.subtotal - (data?.discount || 0));

  return data;
};

const formatMaterialReqs = (materialRequest) => {
  const data = {
    itemDetail: materialRequest?.itemDetail?.map((item) => ({
      item: {
        label: item.item.itemName,
        value: item.item.id,
      },
      quantity: item.quantity,
      unitOfMeasure: {
        label: item.unitOfMeasure.unitOfMeasureName,
        value: item.unitOfMeasure.id,
      }
    })),
    itemType: {
      label: materialRequest.itemType,
      value: materialRequest.itemType
    },
    category: materialRequest?.category?.map((item) => ({
      label: item.name,
      value: item.id,
    }
    )),
    supplier: { label: materialRequest?.supplier.name, value: materialRequest?.supplier.id }
  };

  return data;
};

const formatRfq = (rfq, taxRule) => {
  const { itemType } = rfq;
  const isFixedAsset = itemType === "Asset";

  const data = {
    itemDetail: rfq?.item?.map((item) => {
      const itemData = {
        quantity: item.quantity,
        rate: item.rate,
        amount: item.quantity * item.rate,
      };
      if (taxRule) {
        itemData.tax = {
          label: taxRule?.name,
          value: taxRule?.id,
          purchaseRate: taxRule?.purchaseRate,
          saleRate: taxRule?.saleRate,
        };
      }

      const taxAmount = (itemData.amount * (taxRule?.purchaseRate ?? 0)) / 100;
      const totalAmount = itemData.amount + taxAmount;

      itemData.taxAmount = taxAmount;
      itemData.totalAmount = totalAmount;

      if (isFixedAsset) {
        itemData.label = item.label;
      } else {
        itemData.item = {
          label: item.item?.itemName,
          value: item.item?.id,
        };
      }

      return itemData;
    }),
    itemType: {
      label: itemType,
      value: itemType,
    }
  };

  data.subtotal = data.itemDetail.reduce(
    (prevValue, currValue) => Number(prevValue) + Number(currValue.amount),
    0
  );

  data.total = data.subtotal;

  return data;
};

const quotationInitialState = {
  itemDetail: [],
  subtotal: "",
  discount: "",
  discountType: null,
  total: "",
};

function PurchaseOrderForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState(null);
  const [quotationTableData, setQuotationTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();

  const requestForQuotation = searchParams.get("requestForQuotation");

  const { data: materialRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("MaterialRequestObjectName")), state?.materialRequest?.value],
    () =>
      getDynamicObjectRecordById(dynamicObjectMap.get("MaterialRequestObjectName"), state?.materialRequest?.value),
    {
      enabled: Boolean(state?.materialRequest?.value),
    }
  );

  useEffect(() => {
    if (materialRequestData) {
      const formattedMaterialRequestQuotations = formatMaterialReqs(materialRequestData);
      ref.current.setFormState(formattedMaterialRequestQuotations);
    }
  }, [materialRequestData, state?.materialRequest]);

  const { data: rfqData } = useQuery(
    [kebabCase(dynamicObjectMap.get("RequestForQuotationObjectName")), requestForQuotation],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("RequestForQuotationObjectName")),
        requestForQuotation
      ),
    {
      enabled: Boolean(requestForQuotation),
    }
  );

  const { data: rfqDataQuotationsData } = useQuery(
    [kebabCase(dynamicObjectMap.get("QuotationObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("QuotationObjectName")), {
        requestForQuotation,
        isApproved: true,
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(requestForQuotation),
    }
  );

  const { data: quotationsData } = useQuery(
    [kebabCase(dynamicObjectMap.get("QuotationObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("QuotationObjectName")), {
        supplier: state?.supplier?.value,
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value && !requestForQuotation),
    }
  );

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  useEffect(() => {
    if (rfqData && taxRulesData?.data) {
      const taxRule = taxRulesData?.data?.find((item) => item.name === "Input VAT Partial-recoverable - Residential & Admin (5%)");
      const formattedRfq = formatRfq(rfqData, taxRule);
      setInitialState(formattedRfq);
    }
  }, [rfqData]);

  useEffect(() => {
    if (rfqDataQuotationsData?.data && rfqDataQuotationsData?.data?.length && taxRulesData?.data) {
      const taxRule = taxRulesData?.data?.find((item) => item.name === "Input VAT Partial-recoverable - Residential & Admin (5%)");
      const formattedQuotations = formatQuotation(rfqDataQuotationsData.data[0], taxRule);
      setInitialState({ ...state, ...formattedQuotations });
    }
  }, [rfqDataQuotationsData]);

  useEffect(() => {
    if (quotationsData?.data && quotationsData?.data?.length &&
      state?.supplier && taxRulesData?.data) {
      const taxRule = taxRulesData?.data?.find((item) => item.name === "Input VAT Partial-recoverable - Residential & Admin (5%)");
      const formattedQuotations = quotationsData?.data?.map((r) => formatQuotation(r, taxRule));
      setQuotationTableData(formattedQuotations);
      openModal();
    }
  }, [quotationsData, state?.supplier, taxRulesData]);

  const setTotalWithDiscount = (value) => {
    const formState = ref.current.getState();
    const { discountType, subtotal } = formState;
    if (!subtotal) {
      return;
    }

    if (discountType.value === "Amount") {
      const total = Number(subtotal) - Number((value || 0));
      ref.current.setFormValue("total", total);
      return;
    }

    if (discountType.value === "Percentage") {
      const total = (subtotal) - ((subtotal) * (value || 0)) / 100;
      ref.current.setFormValue("total", total);
    }
  };

  const updateLineItem = async (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    if (key === "itemDetailTable") {
      const { quantity, rate, discount } = value;
      const amount = quantity * rate;
      const { tax } = value;
      let taxAmount = 0;

      const selectedTax = tax;
      if (selectedTax) {
        const parameters = {
          amount,
          amountOfTax: { value: "Exclusive" },
          tax: selectedTax,
          taxAmount: "",
        };
        const { taxAmount: calculatedTaxAmount } = calculateTax(parameters);
        taxAmount = calculatedTaxAmount;
      }

      ref.current.setFormValue("itemDetailTable", {
        ...value,
        amount,
        tax: selectedTax,
        taxAmount,
        totalAmount: (Number(amount) + Number(taxAmount) - Number(discount)),
      });
    } else if (key === "itemDetail") {
      const updatedLineItem = await value.map((item) => {
        const { quantity, rate, discount } = item;
        let amount = quantity * rate;
        const { tax } = item;
        let taxAmount = 0;

        const selectedTax = tax;
        if (selectedTax) {
          const parameters = {
            amount,
            amountOfTax: { value: "Exclusive" },
            tax: selectedTax,
            taxAmount: "",
          };
          const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);
          taxAmount = calculatedTaxAmount;
          amount = principalAmount;
        }
        return {
          ...item,
          amount,
          tax: selectedTax,
          taxAmount,
          totalAmount: (Number(amount) + Number(taxAmount) - Number(discount)),
        };
      });
      const total = await updatedLineItem.reduce(
        (prevValue, currentValue) => Number(prevValue) + Number(currentValue.totalAmount),
        0
      );
      ref.current.setFormValue("itemDetail", updatedLineItem, total);
      ref.current.setFormValue("subtotal", total);
      ref.current.setFormValue("total", total);

      const { discountType, discount } = formState;
      if (!total || !discount) {
        return;
      }

      if (discountType.value === "Amount") {
        const tempTotal = Number(total) - Number(discount);
        ref.current.setFormValue("total", tempTotal);
        return;
      }

      if (discountType.value === "Percentage") {
        const tempTotal = (total) - ((total) * discount) / 100;
        ref.current.setFormValue("total", tempTotal);
      }
    }
  };

  const setTaxAndTotal = (key, value) => {
    updateLineItem(key, value);
  };

  const setTotalWithDiscountType = (value) => {
    const formState = ref.current.getState();

    const { discount, subtotal } = formState;

    if (!subtotal || !discount) {
      return;
    }

    if (value.value === "Amount") {
      const total = Number(subtotal) - Number(discount);

      ref.current.setFormValue("total", total);
      return;
    }

    if (value.value === "Percentage") {
      const total = subtotal - (subtotal * discount) / 100;
      ref.current.setFormValue("total", total);
    }
  };

  const onStateChange = (key, value) => {
    if (key === "materialRequest") {
      setState({
        ...state,
        [key]: value,
      });
    }
    if (key === "supplier") {
      setState({
        ...state,
        [key]: value,
      });

      if (!value) {
        ref.current.setFormState(quotationInitialState);
      }
    }

    if (key === "itemDetailTable") {
      setTaxAndTotal(key, value);
    }

    if (key === "itemDetail") {
      setTaxAndTotal(key, value);
    }

    if (key === "discountType") {
      setTotalWithDiscountType(value);
    }

    if (key === "discount") {
      setTotalWithDiscount(value);
    }
  };

  const handleConfirm = () => {
    if (quotationTableData.length) {
      const selectedQuotation = quotationTableData.find((q) => q.isSelected);

      if (selectedQuotation) {
        ref.current.setFormState(selectedQuotation);
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Supplier Quotation"
        subtitle="Selected supplier has following submitted quotation"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {quotationTableData.length ? (
          <TableWithCheckbox
            data={quotationTableData}
            columns={getSupplierQuotationTableColumns()}
            setData={setQuotationTableData}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("PurchaseOrderObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
      />
    </BoxedContent>
  );
}

export default PurchaseOrderForm;
