import { getTrialBalanceComponentColumns, getTrialBalanceComponentRows } from "./trial-balance-component-statement";
import { getTrialBalanceComponent } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const trialBalanceComponentFilters = {
  ComponentId: "*",
};

const trialBalanceComponentFilterOptions = [
  { key: "componentId", apiKey: "ComponentId", },
];

export default function ReportTrialBalanceComponent({ activeCompany }) {
  return (
    <DynamicReport
      reportName="trial-balance-component"
      activeCompany={activeCompany}
      columnsDataProvider={getTrialBalanceComponentColumns}
      rowsDataProvider={getTrialBalanceComponentRows}
      fetchData={getTrialBalanceComponent}
      exportUrl="finance/reports/trial-balance/xls"
      filtersInitialState={trialBalanceComponentFilters}
      filterOptions={trialBalanceComponentFilterOptions}
      orderBy=""
    />
  );
}
