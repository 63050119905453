import { useSelector } from "react-redux";
import getGeneralJournal from "@/api/finance/reportApi";
import { getGeneralJournalRows, getGeneralJournalColumns } from "./general-journal-statement";
import { selectActiveCompany } from "@/store/appSlice";
import { DynamicReport } from "@/components/dynamic";

const filtersInitialState = {
  scope: "leasing",
};

const additionalFilterOptions = [
  { key: "unitId", apiKey: "filters.unitId" },
  { key: "accountId", apiKey: "filters.accountId" },
  { key: "costCenters", apiKey: "filters.costCenters", keyType: "array" },
];

function ReportGeneralJournal() {
  const activeCompany = useSelector(selectActiveCompany);

  return (
    <DynamicReport
      reportName="general-journal"
      activeCompany={activeCompany}
      columnsDataProvider={() => getGeneralJournalColumns(activeCompany)}
      rowsDataProvider={getGeneralJournalRows}
      fetchData={getGeneralJournal}
      exportUrl="finance/reports/general-journal/xls"
      filtersInitialState={filtersInitialState}
      orderBy="Type"
      startDate={new Date()}
      showDateRangeFilter
      filterOptions={additionalFilterOptions}
    />
  );
}

export default ReportGeneralJournal;
