import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import {
  BoxedContent, Header, HeaderLeftContent
} from "@/components/common";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";

function ReportPettyCashClaimStatementWrapper() {
  const { id } = useParams();
  const { data: pettyCashClaimData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("PettyCashClaimTransactionObjectName")), id],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("PettyCashClaimTransactionObjectName"), id),
    {
      enabled: Boolean(id),
    }
  );

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Petty Cash Claim Statement" subtitle="" icon="file-check-stroke-icon" />}
      />
      {!isLoading && (
      <>
        <DynamicObjectDetails objectName="PettyCashClaim" id={pettyCashClaimData?.id} showHeader={false} />
        {/* <ReportPettyCashClaim id={pettyCashClaimData?.id} /> */}
      </>
      )}
    </BoxedContent>
  );
}

export default ReportPettyCashClaimStatementWrapper;
