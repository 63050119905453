import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { FormControl } from "@hydra/atom/components";
import {
  ReportSupplierStatement
} from "@/components/finance/reporting";
import { formatApiPayloadDate } from "@/utils/helpers";
import {
  BoxedContent, Header, SvgIcon, IconButton, HeaderLeftContent, CustomizedDatePicker
} from "@/components/common";
import { selectActiveCompany } from "@/store/appSlice";
import appSettings from "@/settings";

function ReportSupplierStatementWrapper() {
  const { id } = useParams();
  const activeCompany = useSelector(selectActiveCompany);
  const [date, setDate] = useState(new Date());

  const exportExcel = (dateVal) => window.open(`${appSettings.baseUrl}/procurement/reports/supplier/${id}/xls?companyId=${activeCompany.id}&date=${formatApiPayloadDate(dateVal)}`, "_blank", "noopener,noreferrer");

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Supplier Statement" subtitle="" icon="file-check-stroke-icon" />}
        rightContent={(
          <div className="custom-switch">
            <div className="col-md general-date-range">
              <FormControl>
                <CustomizedDatePicker
                  name="date"
                  value={date}
                  onChange={(value) => setDate(value)}
                />
              </FormControl>
            </div>
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel(date)}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
          </div>
        )}
      />
      <ReportSupplierStatement id={id} activeCompany={activeCompany} />
    </BoxedContent>
  );
}

export default ReportSupplierStatementWrapper;
