import {
  getAgingRows, getSupplierStatementRows,
  getSupplierStatementColumns,
  getAgingColumns
} from "./supplier-statement";
import { getSupplierAdvancePaymentRows, getSupplierAdvancePaymentColumns } from "./supplier-advance-payment-statement";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getSupplierOpenTransaction, getSupplierAging, getSupplierAdvancePayment } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";
import {
  BoxedContent, Header, HeaderLeftContent
} from "@/components/common";

export default function ReportSupplierStatement({ id, activeCompany }) {
  const supplierStatementFilters = {
    SupplierId: id
  };

  const supplierAdvancePaymentFilters = {
    SupplierKey: id,
    PaymentNumber: "*",
    ExpenseNumber: "*",
  };

  const supplierAdvancePaymentFilterOptions = [
    { key: "supplierKey", apiKey: "SupplierKey" },
    { key: "paymentNumber", apiKey: "PaymentNumber", keyType: "string" },
    { key: "expenseNumber", apiKey: "ExpenseNumber", keyType: "string" },
  ];

  return (
    <>
      <DynamicObjectDetails objectName="Supplier" id={id} showHeader={false} showTransfers={false} />
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Advance Payments" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="suppliers-advance-payments-with-expense"
          activeCompany={activeCompany}
          columnsDataProvider={getSupplierAdvancePaymentColumns}
          rowsDataProvider={getSupplierAdvancePaymentRows}
          fetchData={getSupplierAdvancePayment}
          showDateFilter
          showDateRangeFilter={false}
          exportUrl="reports/export-query-report/suppliers-advance-payments-with-expense"
          filtersInitialState={supplierAdvancePaymentFilters}
          filterOptions={supplierAdvancePaymentFilterOptions}
          orderBy="AdvancePaymentNumber"
        />
      </BoxedContent>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Open Transaction" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="open-transaction"
          activeCompany={activeCompany}
          columnsDataProvider={getSupplierStatementColumns}
          rowsDataProvider={getSupplierStatementRows}
          fetchData={getSupplierOpenTransaction}
          showDateFilter
          showDateRangeFilter={false}
          exportUrl="reports/export-query-report/supplier-open-transactions"
          filtersInitialState={supplierStatementFilters}
          orderBy=""
          isPaginated={false}
        />
      </BoxedContent>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Aging" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="aging"
          activeCompany={activeCompany}
          columnsDataProvider={getAgingColumns}
          rowsDataProvider={getAgingRows}
          fetchData={getSupplierAging}
          showDateFilter
          showDateRangeFilter={false}
          exportUrl="reports/export-query-report/supplier-aging-report"
          filtersInitialState={supplierStatementFilters}
          orderBy=""
          isPaginated={false}
        />
      </BoxedContent>
    </>
  );
}
