import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getBankAccountStatementRows, getBankAccountStatementColumns } from "./bank-account-statement";
import { getBankAccountStatement } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

function ReportBankAccountStatement({ id, activeCompany }) {

  const bankAccountStatementFilters = {
    TenantNumber: "*",
    CompanyName: "*",
    ContractNumber: "*",
    ComponentNumber: "*",
    TenantKey: "*",
    PaymentMethod: "*",
    BankAccount: "*",
    BankAccountKey: id,
    PaymentType: "*",
  };

  const bankAccountStatementOptions = [
    { key: "tenantNumber", apiKey: "TenantNumber", keyType: "number" },
    { key: "componentNumber", apiKey: "ComponentNumber", keyType: "number" },
    { key: "contractNumber", apiKey: "ContractNumber", keyType: "label" },
  ];

  return (
    <>
      <DynamicObjectDetails objectName="BankAccount" id={id} showHeader={false} showTransfers={false} />
      <DynamicReport
        reportName="bank-account-statement"
        activeCompany={activeCompany}
        columnsDataProvider={getBankAccountStatementColumns}
        rowsDataProvider={getBankAccountStatementRows}
        fetchData={getBankAccountStatement}
        exportUrl="reports/export-query-report/bank-account-statement"
        filtersInitialState={bankAccountStatementFilters}
        filterOptions={bankAccountStatementOptions}
        orderBy="BankAccount"
      />
    </>
  );
}

export default ReportBankAccountStatement;
