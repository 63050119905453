import { kebabCase, sortBy, startCase } from "lodash";
import pluralize from "pluralize";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

import AdminLogo from "@/assets/images/logos/admin.svg";
import FacilityLogo from "@/assets/images/logos/facility.svg";
import FinanceLogo from "@/assets/images/logos/finance.svg";
import LeasingLogo from "@/assets/images/logos/property.svg";
import SalesLogo from "@/assets/images/logos/sales.svg";
import WarehouseLogo from "@/assets/images/logos/warehouse.svg";

const getAppLogo = (applicationName) => {
  switch (applicationName) {
    case "FacilityManagement":
      return FacilityLogo;

    case "Finance":
      return FinanceLogo;

    case "Sales":
      return SalesLogo;

    case "Leasing":
      return LeasingLogo;

    case "Warehousing":
      return WarehouseLogo;

    default:
      return AdminLogo;
  }
};

export const menuItems = [
  {
    type: "menu",
    title: "Build",
    isExpanded: true,
    items: [
      {
        type: "link",
        href: "/admin/tasks",
        text: "Tasks",
        icon: "ship-wheel-icon",
      },
      {
        type: "link",
        href: "/admin/dynamic-object",
        text: "Object Manager",
        icon: "dashboard-icon",
      },
      {
        type: "link",
        href: "/admin/dynamic-dashboard",
        text: "Dashboard Manager",
        icon: "pie-chart-icon",
      },
      {
        type: "link",
        href: "/admin/company-settings",
        text: "Company Settings",
        icon: "settings-icon",
      },
      {
        type: "link",
        href: "/admin/user-management",
        text: "User Management",
        icon: "users-stroke-icon",
      },
    ],
  },
];

export const adminApp = {
  label: "Admin",
  value: "Admin",
  status: "Active",
  image: AdminLogo,
  menuItems,
};

const prependFacilityMenuItems = [
  {
    type: "link",
    href: "/facility-management/dashboard",
    text: "Dashboard",
    icon: "dashboard-icon-2",
  },
  {
    type: "link",
    href: "/facility-management/plan-board",
    text: "Planboard",
    icon: "document-icon",
  },
];

export const apps = [adminApp];

export const settingsMenu = [
  {
    type: "profile",
    href: "/configuration",
    text: "Configuration",
    icon: "profile-icon",
  },
];

const checkPermission = (permissions, objectName) => {
  const permission = permissions.find((p) => p.objectName === objectName);

  if (permission) {
    return permission.permitted.includes("Any") || permission.permitted.includes("View");
  }

  return false;
};

const prepareSidebarLink = (objectName, applicationName, text) => ({
  type: "link",
  href: `/${kebabCase(applicationName)}/${kebabCase(objectName)}`,
  text: text ?? pluralize(startCase(objectName)),
});

export const formatNavbarItems = (items, permissions, roles) => {
  const permittedApps = permissions
    .filter((p) => p.objectType === "Module")
    .map((p) => p.objectName);
  const arr = apps.filter((a) => permittedApps.includes(a.value));

  items.forEach((item) => {
    const { applicationName, objects } = item;

    const groups = [];
    let sidebarItems = [];

    const sortedItems = sortBy(objects, (sidebarItem) => sidebarItem.sequence);

    sortedItems
      .filter((s) => s.sequence)
      .forEach((menuItem) => {
        const {
          objectName, icon, group, isDynamicObject
        } = menuItem;

        const sidebarItem = {
          type: "link",
          text: pluralize(startCase(objectName)),
          objectName,
          href: `/${kebabCase(applicationName)}/${kebabCase(pluralize(objectName))}`,
          icon: icon || "dashboard-icon-2",
          isDynamicObject,
        };

        if (!group) {
          sidebarItems.push(sidebarItem);
          return;
        }

        if (group && !groups.includes(group)) {
          sidebarItems.push({
            type: "submenu",
            text: startCase(group),
            group,
            icon: sidebarItem.icon,
            items: [sidebarItem],
          });

          groups.push(group);
        } else {
          const groupItem = sidebarItems.find((i) => i.group === group);

          groupItem.items.push(sidebarItem);
        }
      });

    let sortedGroups = sidebarItems.map((sidebarItem) => {
      if (sidebarItem.group) {
        const sortedSubMenuItems = sortBy(
          sidebarItem.items,
          (sidebarSubMenuItem) => sidebarSubMenuItem.groupSequence
        );
        sidebarItem.items = sortedSubMenuItems;
        return sidebarItem;
      }

      return sidebarItem;
    });

    const tasksMenuItem = {
      type: "link",
      href: `/${kebabCase(applicationName)}/tasks`,
      text: "Tasks",
      icon: "ship-wheel-icon",
    };

    switch (applicationName) {
      case "FacilityManagement": {
        sortedGroups = sortedGroups.map((groupItem) => {
          if (groupItem.group === "Financials" && groupItem.type === "submenu") {
            if (checkPermission(permissions, dynamicObjectMap.get("PettyCashObjectName"))) {
              const pettyCashTransactionItem = {
                icon: "document-icon",
                type: "link",
                href: `/${kebabCase(applicationName)}/petty-cash-transactions`,
                text: "Transactions",
              };

              const pettyCashDashboard = {
                icon: "dashboard-icon-2",
                type: "link",
                href: `/${kebabCase(applicationName)}/petty-cash-dashboard`,
                text: "Dashboard",
              };

              const pettyCashes = {
                icon: "dashboard-icon-2",
                type: "link",
                href: `/${kebabCase(applicationName)}/petty-cashes`,
                text: "All Petty Cashes",
              };

              groupItem.items = groupItem.items.map((list) => {
                if (list.text === "Supervisor Petty Cash Requests") {
                  list.text = "Petty Cash Request (Supervisor)";
                }
                if (list.text === "Petty Cash Requests") {
                  list.text = "Petty Cash Request";
                }
                return list;
              });

              groupItem.items.unshift(pettyCashDashboard);
              groupItem.items.push(pettyCashTransactionItem, pettyCashes);
            }
          }

          if (checkPermission(permissions, dynamicObjectMap.get("WorkOrderObjectName"))) {
            groupItem.items = groupItem.items?.map((list) => {
              if (list.text === "Work Order Jobs") {
                list.text = "Jobs";
              }
              return list;
            });
          }

          if (checkPermission(permissions, dynamicObjectMap.get("EmployeeObjectName"))) {
            if (groupItem.group === "Technicians" && groupItem.type === "submenu") {
              const technicianListItem = {
                icon: "document-icon",
                type: "link",
                href: `/${kebabCase(applicationName)}/technician`,
                text: "Technicians Pool",
              };
              groupItem.items.push(technicianListItem);
              groupItem.items = groupItem.items.filter((itm) => itm.objectName !== "Employee");
              groupItem.items = groupItem.items.map((list) => {
                if (list.text === "Technician Skills") {
                  list.text = "Skills";
                }
                return list;
              });
              return groupItem;
            }
          }

          return groupItem;
        });

        if (checkPermission(permissions, dynamicObjectMap.get("WorkOrderObjectName"))) {
          sidebarItems = [tasksMenuItem, ...prependFacilityMenuItems, ...sortedGroups];
        } else {
          sidebarItems = [tasksMenuItem, ...sortedGroups];
        }

        if (checkPermission(permissions, dynamicObjectMap.get("RequestForQuotationObjectName")) && roles?.includes("supplier")) {
          const requestForQuotationItem = [{
            type: "link",
            href: `/${kebabCase(applicationName)}/request-for-quotation`,
            text: "Request For Quotation",
            icon: "document-icon",
          },
          {
            type: "link",
            href: `/${kebabCase(applicationName)}/quotation`,
            text: "Quotations",
            icon: "document-icon",
          },
          ];
          sidebarItems = [tasksMenuItem, ...requestForQuotationItem, ...sortedGroups];
        }
        sidebarItems = sidebarItems.filter((sideBaritem) => !(sideBaritem.type === "link" && sideBaritem.objectName === "Entity"));
        break;
      }

      case "Leasing":
        sidebarItems = [tasksMenuItem, ...sortedGroups];
        break;

      case "Finance": {
        sortedGroups = sortedGroups.map((groupItem) => {
          if (groupItem.group === "Purchasing" && groupItem.type === "submenu") {
            if (
              checkPermission(permissions, dynamicObjectMap.get("RequestForQuotationObjectName"))
            ) {
              groupItem.items.unshift(
                prepareSidebarLink(
                  dynamicObjectMap.get("RequestForQuotationObjectName"),
                  applicationName
                )
              );
            }

            if (checkPermission(permissions, dynamicObjectMap.get("StockTransferObjectName"))) {
              groupItem.items.unshift(
                prepareSidebarLink(dynamicObjectMap.get("StockTransferObjectName"), applicationName)
              );
            }

            if (checkPermission(permissions, dynamicObjectMap.get("MaterialRequestObjectName"))) {
              groupItem.items.unshift(
                prepareSidebarLink(
                  dynamicObjectMap.get("MaterialRequestObjectName"),
                  applicationName
                )
              );
            }

            return groupItem;
          }

          if (groupItem.group === "Leasing" && groupItem.type === "submenu") {
            if (checkPermission(permissions, dynamicObjectMap.get("QuotationObjectName"))) {
              groupItem.items.unshift(
                prepareSidebarLink(dynamicObjectMap.get("QuotationObjectName"), applicationName)
              );
            }

            if (checkPermission(permissions, dynamicObjectMap.get("ReservationObjectName"))) {
              groupItem.items.unshift(
                prepareSidebarLink(dynamicObjectMap.get("ReservationObjectName"), applicationName)
              );
            }

            return groupItem;
          }

          if (groupItem.group === "FacilityManagement" && groupItem.type === "submenu") {
            if (checkPermission(permissions, dynamicObjectMap.get("FacilityContractObjectName"))) {
              groupItem.items.push(
                prepareSidebarLink(
                  dynamicObjectMap.get("FacilityContractObjectName"),
                  applicationName
                )
              );
            }

            if (checkPermission(permissions, dynamicObjectMap.get("BlanketAgreementObjectName"))) {
              groupItem.items.push(
                prepareSidebarLink(
                  dynamicObjectMap.get("BlanketAgreementObjectName"),
                  applicationName
                )
              );
            }

            return groupItem;
          }

          if (groupItem.group === "Cost Centers" && groupItem.type === "submenu") {
            if (checkPermission(permissions, dynamicObjectMap.get("WarehouseObjectName"))) {
              groupItem.items.push(
                prepareSidebarLink(dynamicObjectMap.get("WarehouseObjectName"), applicationName)
              );
            }

            if (checkPermission(permissions, dynamicObjectMap.get("OrganizationObjectName"))) {
              groupItem.items.push(
                prepareSidebarLink(dynamicObjectMap.get("OrganizationObjectName"), applicationName)
              );
            }

            return groupItem;
          }

          if (groupItem.group === "Fixed Assets" && groupItem.type === "submenu") {
            if (checkPermission(permissions, dynamicObjectMap.get("ItemObjectName"))) {
              groupItem.items.unshift(
                prepareSidebarLink("AssetRegister", applicationName, "Asset Register")
              );
            }

            return groupItem;
          }

          return groupItem;
        });

        const masterMenuItem = {
          type: "submenu",
          text: "Master",
          icon: "dashboard-icon",
          isExpanded: true,
          items: [],
        };

        const masterMenuItems = [
          {
            objectName: dynamicObjectMap.get("EmployeeObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("TenantObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("SupplierObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("ItemObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("BuildingObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("ComponentObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("FloorObjectName"),
          },
          {
            objectName: dynamicObjectMap.get("UnitObjectName"),
          },
        ];

        if (!roles.includes("tsa")) {
          masterMenuItems.forEach((menuItem) => {
            if (checkPermission(permissions, menuItem.objectName)) {
              masterMenuItem.items.push(prepareSidebarLink(menuItem.objectName, applicationName));
            }
          });
        }

        const financeMenuItems = [
          {
            type: "submenu",
            text: "Budget",
            icon: "pie-chart-2-stroke-icon",
            isExpanded: true,
            items: [],
          },
          {
            type: "submenu",
            text: "Accountant",
            icon: "coins-stacked-stroke-icon",
            isExpanded: true,
            items: [],
          },
          {
            type: "link",
            text: "Reporting",
            icon: "file-check-stroke-icon",
            href: "/finance/reports",
          },
        ];

        if (!roles.includes("tsa")) {
          financeMenuItems.forEach((groupItem) => {
            if (groupItem.text === "Budget") {
              if (checkPermission(permissions, "Budget")) {
                groupItem.items.push(prepareSidebarLink("Budget", applicationName));
              }

              if (checkPermission(permissions, "BudgetCategory")) {
                groupItem.items.push(prepareSidebarLink("BudgetCategory", applicationName));
              }
            }

            if (groupItem.text === "Accountant") {
              if (checkPermission(permissions, "LedgerAccount")) {
                groupItem.items.push(prepareSidebarLink("ChartOfAccounts", applicationName, "Chart of Accounts"));
              }

              if (checkPermission(permissions, "Transfer")) {
                groupItem.items.push(prepareSidebarLink("JournalEntry", applicationName, "Journal Entry"));
              }

              if (checkPermission(permissions, "AccountingPeriod")) {
                groupItem.items.push(prepareSidebarLink("AccountingPeriod", applicationName));
              }

              if (checkPermission(permissions, "TaxRule")) {
                groupItem.items.push(prepareSidebarLink("TaxRule", applicationName));
              }
            }
          });
        }

        sidebarItems = [tasksMenuItem, ...sortedGroups, masterMenuItem, ...financeMenuItems];
        break;
      }

      case "Sales":
        sidebarItems = [tasksMenuItem, ...sortedGroups];
        break;

      case "Warehousing":
        sidebarItems = [tasksMenuItem, ...sortedGroups];
        break;

      default:
        break;
    }

    arr.push({
      label: startCase(applicationName),
      value: applicationName,
      status: "Active",
      image: getAppLogo(applicationName),
      menuItems: [
        {
          type: "menu",
          title: "Overview",
          items: sidebarItems,
          isExpanded: true,
        },
      ],
    });
  });

  return arr;
};
