import PropTypes from "prop-types";
import { useTable } from "react-table";
import { Input } from "@hydra/atom/components";
import { useEffect, useState, useRef } from "react";
import { pascalize } from "humps";
import { useVirtualizer } from "@tanstack/react-virtual";
import { SvgIcon } from "@/components/common";

function Table({
  columns, data, updateMyData, className = "", type, allowMultiple, isSelectAll, onSelectAll, virtualization
}) {
  const [columnWidths, setColumnWidths] = useState([]);
  const headerRef = useRef(null);
  const parentRef = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
    updateMyData,
  });

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 10,
  });
  useEffect(() => {
    if (headerRef.current) {
      const headerCells = headerRef.current.querySelectorAll("th");
      const widths = Array.from(headerCells).map((cell) => cell.offsetWidth);
      setColumnWidths(widths);
    }
  }, [headerGroups]);
  if (virtualization) {
    return (
      <div ref={parentRef} className={`table-wrapper-virtual ${className}`}>
        <table {...getTableProps()} className={`table ${className}`}>
          <thead ref={headerRef}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="table-header-row-virtual">
                {headerGroup.headers.map((column, index) => (column.Header === "Select" && type === "without-checkbox" ? "" : (
                  <th {...column.getHeaderProps()} className="table-header-cell-virtual">
                    <div className="table-header-cell-content">
                      {column.Header === "Select" && allowMultiple && (
                        <input
                          type="checkbox"
                          className="table-checkbox"
                          checked={isSelectAll}
                          onChange={onSelectAll}
                        />
                      )}
                      {column.render("Header")}
                    </div>
                  </th>
                )))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ height: `${rowVirtualizer.getTotalSize()}px` }} className={virtualization ? "table-body-virtual" : ""}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps({
                    style: {
                      transform: `translateY(${virtualRow.start}px)`,
                      height: `${virtualRow.size}px`,
                    },
                  })}
                  className="table-row-virtual"
                >
                  {row.cells.map((cell, index) => {
                    if (type === "without-checkbox" && cell.column.Header === "Select") {
                      return "";
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="table-body-cell table-body-cell-virtual"
                        style={{ width: columnWidths[index] || "auto" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={`table-wrapper ${className}`}>
      <table {...getTableProps()} className={`table ${className}`}>
        <thead ref={headerRef}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="table-header-row">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="table-header-cell">
                  <div className="table-header-cell-content">
                    {column.Header === "Select" && allowMultiple && (
                      <input
                        type="checkbox"
                        className="table-checkbox"
                        checked={isSelectAll}
                        onChange={onSelectAll}
                      />
                    )}
                    {column.render("Header")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="table-row">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="table-body-cell">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  virtualization: PropTypes.bool,
};

Table.defaultProps = {
  virtualization: false
};

function TableWithCheckbox({
  columns, data, setData, allowMultiple = false, className = "", selectAll = false, searchKey = [], type = "default", virtualization = false
}) {
  const [search, setSearch] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(selectAll);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (search.trim()) {
      const lowerSearch = search.toLowerCase();
      setFilteredData(
        data.filter((item) =>
          searchKey.some((key) => {
            const value = key.split(".").reduce((acc, part) => acc?.[part], item);
            return value?.toString().toLowerCase().includes(lowerSearch);
          })
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [search, data]);

  const updateMyData = (rowIndex, columnId, value) => {
    const updatedData = [...filteredData];
    updatedData[rowIndex][columnId] = value;
    setFilteredData(updatedData);
    setData(updatedData);
  };

  const onSelectAll = () => {
    const updatedData = filteredData.map((item) => ({
      ...item,
      isSelected: !isSelectAll,
    }));
    setFilteredData(updatedData);
    setData(updatedData);
    setIsSelectAll((prev) => !prev);
  };

  return (
    <>
      {searchKey.length > 0 && (
        <div className="row">
          <h1 className="filter-title">Search</h1>
          <Input
            className="search-bar-container"
            placeholder={`Search by ${searchKey.map((key) =>
              pascalize(key.split(".")[0]).replace(/([a-z])([A-Z])/g, "$1 $2")
            ).join(", ")}`}
            value={search}
            onChange={setSearch}
          >
            <Input.PrependIcon>
              <SvgIcon icon="search" />
            </Input.PrependIcon>
          </Input>
        </div>
      )}
      <div className={!virtualization ? "table-wrapper scrollable" : ""} style={!virtualization ? { maxHeight: "40vh" } : {}}>
        <Table
          className={className}
          columns={columns}
          data={filteredData}
          updateMyData={updateMyData}
          allowMultiple={allowMultiple}
          isSelectAll={isSelectAll}
          onSelectAll={onSelectAll}
          type={type}
          virtualization={virtualization}
        />
      </div>
    </>
  );
}

TableWithCheckbox.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool,
  selectAll: PropTypes.bool,
  searchKey: PropTypes.array,
  type: PropTypes.string,
  virtualization: PropTypes.bool,
};

TableWithCheckbox.defaultProps = {
  allowMultiple: false,
  selectAll: false,
  searchKey: [],
  type: "default",
  virtualization: false
};

export default TableWithCheckbox;
