import { useCallback } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Breadcrumb } from "@hydra/atom/components";
import { kebabCase, startCase } from "lodash";
import pluralize, { isSingular } from "pluralize";

import { SvgIcon } from "@/components/common";
import { selectActiveApp } from "@/store/appSlice";

const filterPaths = [
  // Apps
  "admin",
  "facility-management",
  "finance",
  "sales",
  "leasing",
  "warehousing",

  // Facility
  "details"
];

function Home() {
  const activeApp = useSelector(selectActiveApp);
  const href = activeApp ? `/${kebabCase(activeApp?.value)}/tasks` : "/";

  return (
    <NavLink to={href} className="breadcrumb-icon">
      <div className="home-icon">
        <SvgIcon icon="home-icon" />
      </div>
    </NavLink>
  );
}

function BreadcrumbItem({ title, href }) {
  const location = useLocation();

  return (
    <NavLink
      to={href}
      className={`breadcrumb-title ${location.pathname === href && "active-link"}`}
    >
      {title}
    </NavLink>
  );
}

function Breadcrumbs() {
  const activeApp = useSelector(selectActiveApp);
  const location = useLocation();
  const {
    id, objectName, workOrderId, invoiceId, taskId, tenantId,
    buildingId, unitId, technicianId
  } =
    useParams();

  const getBreadcrumbItems = useCallback(() => {
    const breadcrumb = [{ title: <Home /> }];
    const paths = location.pathname?.split("/");
    let path = "";
    paths.forEach((href) => {
      if (href) {
        if (href === objectName && isSingular(href)) {
          breadcrumb.push({
            title: (
              <BreadcrumbItem
                title={startCase(pluralize(href))}
                href={`${path}/${pluralize(href)}`}
              />
            ),
          });
        }

        if (href === "work-order") {
          path = `${path}/${pluralize(href)}`;
        } else if (href === "ledger-account") {
          path = `${path}/chart-of-accounts`;
        } else {
          path = `${path}/${href}`;
        }

        // Note: add check with kebabCase to show module in breadcrumb
        if (href === activeApp?.value) {
          breadcrumb.push({
            title: <BreadcrumbItem title={activeApp?.label} href={`/${href}/tasks`} />,
          });
        } else if (href === id) {
          if (path.includes("/details/")) {
            breadcrumb.push({
              title: <BreadcrumbItem title="View" href={path} />,
            });
          } else if (path.includes("reports")) {
            breadcrumb.push({
              title: <BreadcrumbItem title={id} href={path} />,
            });
          } else {
            breadcrumb.push({
              title: <BreadcrumbItem title="Edit" href={path} />,
            });
          }
        } else if (href === objectName) {
          if (!isSingular(href)) {
            breadcrumb.push({
              title: <BreadcrumbItem title={startCase(href)} href={path} />,
            });
          }
        } else if (!filterPaths.includes(href)) {
          let title = startCase(href);

          switch (href) {
            case "work-order":
              title = pluralize(title);
              break;
            case workOrderId:
            case invoiceId:
            case taskId:
            case unitId:
            case tenantId:
            case buildingId:
            case technicianId:
              title = "View";
              break;
            default:
              break;
          }

          breadcrumb.push({
            title: <BreadcrumbItem title={title} href={path} />,
          });
        }
      }
    });

    return breadcrumb;
  }, [location]);

  return <Breadcrumb items={getBreadcrumbItems()} />;
}

export default Breadcrumbs;
