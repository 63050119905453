import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export function getAgingColumns() {
  const colArr = [
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Not Due",
      value: true,
      columnId: "notDue",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "1 Month Overdue",
      value: true,
      columnId: "oneMonth",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "2 Months Overdue",
      value: true,
      columnId: "twoMonth",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "3 Months Overdue",
      value: true,
      columnId: "threeMonth",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Over 3 Months Overdue",
      value: true,
      columnId: "overThreeMonths",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Total",
      value: true,
      columnId: "total",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    }

  ];

  return colArr;
}

export function getSupplierStatementColumns() {
  const colArr = [
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Creation Date",
      value: true,
      columnId: "creationDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Invoice Num.", value: true, columnId: "supplierInvoiceNr", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Due Date", value: true, columnId: "dueDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Transaction Date", value: true, columnId: "transactionDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Original Amount", value: true, columnId: "total", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(formatCurrency(value || 0) || "")
    },
    {
      label: "Paid Amount", value: true, columnId: "paidAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(formatCurrency(value || 0) || "")
    },
    {
      label: "Balance", value: true, columnId: "balance", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(formatCurrency(value || 0) || "")
    }
  ];

  return colArr;
}

export function getAdvancePaymentColumns() {
  const colArr = [
    {
      label: "Advance Payment Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Date",
      value: true,
      columnId: "date",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Type", value: true, columnId: "type", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(formatCurrency(value || 0) || "")
    }
  ];

  return colArr;
}

export function getSupplierPaidInvoicesColumns() {
  const colArr = [
    {
      label: "Type",
      value: true,
      columnId: "invoiceType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense Number", value: true, columnId: "expenseNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Due Date", value: true, columnId: "dueDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Actual Date", value: true, columnId: "actualDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Method", value: true, columnId: "paymentMethod", width: estimatedColumnWidth("method"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Paid", value: true, columnId: "paid", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    }
  ];

  return colArr;
}
