import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getCollectChequeColumns() {
  const colArr = [
    {
      label: "Number", value: true, columnId: "number", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Number", value: true, columnId: "paymentReceiptNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Date", value: true, columnId: "paymentReceiptDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(String(value || ""))) : "")
    },
    {
      label: "Component Number", value: true, columnId: "componentNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Name", value: true, columnId: "componentName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number", value: true, columnId: "unitNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number", value: true, columnId: "contractNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Number", value: true, columnId: "tenantNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name", value: true, columnId: "tenantName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Due Date", value: true, columnId: "dueDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(String(value || ""))) : "")
    },
    {
      label: "Cheque Number", value: true, columnId: "chequeNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Customer Bank Name", value: true, columnId: "bankName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Description", value: true, columnId: "paymentReceiptMemo", width: estimatedColumnWidth("description"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Pdc Status", value: true, columnId: "pdcStatus", width: estimatedColumnWidth("status"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Status", value: true, columnId: "paymentStatus", width: estimatedColumnWidth("status"), resizable: true, formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
