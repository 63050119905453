import { useEffect, useState } from "react";
import { Input } from "@hydra/atom/components";
import { useSelector } from "react-redux";
import {
  BoxedContent, Header, Accordion, SvgIcon, HeaderLeftContent
} from "@/components/common";
import { ReportTable } from "@/components/property";
import { selectPermissions } from "@/store/userSlice";
import { checkIfValueExists } from "@/utils/report/helpers";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

const menuItems = [
  {
    name: "Profit and Loss Statement",
    to: "/finance/reports/profit-and-loss",
    icon: "dollar-icon",
    value: "profit-and-loss",
  },
  {
    name: "Balance Sheet",
    to: "/finance/reports/balance-sheet",
    icon: "balance-icon",
    value: "balance-sheet",
  },
  {
    name: "Cash Flow Statement",
    to: "/finance/reports/cash-flow-statement",
    icon: "cash-flow-icon",
    value: "cash-flow-statement",
  },
  {
    name: "Trial Balance",
    to: "/finance/reports/trial-balance",
    icon: "balance-icon-2",
    value: "trial-balance",
  },
  {
    name: "Tax Liability",
    to: "/finance/reports/tax-liability",
    icon: "report",
    value: "tax-liability",
  },
  {
    name: "Journal Ledger",
    to: "/finance/reports/journal-ledger",
    icon: "journal-ledger",
    value: "journal-ledger",
  },
  {
    name: "General Journal",
    to: "/finance/reports/general-journal",
    icon: "journal",
    value: "general-journal",
  },
  {
    name: "Profit and Loss Budget Vs Actual Statement",
    to: "/finance/reports/profit-and-loss-vs-actual",
    icon: "profit-loss-icon",
    value: "profit-and-loss-vs-actual",
  },
  {
    name: "Fixed Asset Listing",
    to: "/finance/reports/fixed-asset-listing",
    icon: "fixed-assest-list",
    value: "fixed-asset-listing",
  },
  {
    name: "VAT Summary Report",
    to: "/finance/reports/vat-summary",
    icon: "vat-summary-report",
    value: "vat-summary",
  },
  {
    name: "Standard Rated Sales",
    to: "/finance/reports/standard-rate-sale",
    icon: "report",
    value: "standard-rate-sale",
  },
  {
    name: "Standard Rated Purchase",
    to: "/finance/reports/standard-rate-purchase",
    icon: "report",
    value: "standard-rate-purchase",
  },
  {
    name: "Zero Rated Sales",
    to: "/finance/reports/zero-rate-sale",
    icon: "report",
    value: "zero-rate-sale",
  },
  {
    name: "Rent Roll Report",
    to: "/finance/reports/rent-roll",
    icon: "report",
    value: "rent-roll",
  },
  {
    name: "Rent Roll Archive Report",
    to: "/finance/reports/rent-roll-archive",
    icon: "report",
    value: "rent-roll-archive",
  },
  {
    name: "Forth Coming Renewals",
    to: "/finance/reports/forthcoming-renewals",
    icon: "dashboard-icon-3",
    value: "forthcoming-renewals",
  },
  {
    name: "Tenant Statement",
    to: "/finance/reports/tenant-statement",
    icon: "tenant-statement",
    value: "tenant-statement",
  },
  {
    name: "VAT Detail",
    to: "/finance/reports/vat-details",
    icon: "journal-ledger",
    value: "vat-details",
  },
  {
    name: "VAT Exception",
    to: "/finance/reports/vat-exceptions",
    icon: "journal-ledger",
    value: "vat-exceptions",
  },
  {
    name: "Tenant Aging Report",
    to: "/finance/reports/customer-aging",
    icon: "agent-icon-2",
    value: "customer-aging",
  },
  {
    name: "Vacancy Aging Report",
    to: "/finance/reports/vacancy-aging",
    icon: "agent-icon-2",
    value: "vacancy-aging",
  },
  {
    name: "Forthcoming Vacancy",
    to: "/finance/reports/forthcoming-vacancy",
    icon: "agent-icon-2",
    value: "forthcoming-vacancy",
  },
  {
    name: "Occupancy Report",
    to: "/finance/reports/occupancy",
    icon: "agent",
    value: "occupancy",
  },
  {
    name: "Opex Report",
    to: "/finance/reports/opex-report",
    icon: "agent-icon-2",
    value: "opex-report",
  },
  {
    name: "Input VAT Report",
    to: "/finance/reports/vat-input",
    icon: "agent-icon-2",
    value: "vat-input",
  },
  {
    name: "Output VAT Report",
    to: "/finance/reports/vat-output",
    icon: "report",
    value: "vat-output",
  },
  {
    name: "FAR Report",
    to: "/finance/reports/fixed-assets-register",
    icon: "agent-icon-2",
    value: "fixed-assets-register",
  },
  {
    name: "Bounce Cheque",
    to: "/finance/reports/bounce-cheque",
    icon: "cheque",
    value: "bounce-cheque",
  },
  {
    name: "Clear Cheque",
    to: "/finance/reports/clear-cheque",
    icon: "cheque",
    value: "clear-cheque",
  },
  {
    name: "To Be Clear Cheque",
    to: "/finance/reports/collect-cheque",
    icon: "cheque",
    value: "collect-cheque",
  },
  {
    name: "Supplier Aging Report",
    to: "/finance/reports/vendor-aging",
    icon: "agent-icon-2",
    value: "vendor-aging",
  },
  {
    name: "Supplier Statement",
    to: "/finance/reports/supplier-aging-report",
    icon: "agent-icon-2",
    value: "supplier-aging-report",
  },
  {
    name: "Supplier Payments",
    to: "/finance/reports/supplier-payment",
    icon: "agent-icon-2",
    value: "supplier-payment",
  },
  {
    name: "Supplier Paid Invoices",
    to: "/finance/reports/supplier-paid-invoices",
    icon: "agent-icon-2",
    value: "supplier-paid-invoices",
  },
  {
    name: "Unit Availability",
    to: "/finance/reports/unit-availability",
    icon: "agent-icon-2",
    value: "unit-availability",
  },
  {
    name: "Unit Ready Report",
    to: "/finance/reports/unit-ready-report",
    icon: "agent-icon-2",
    value: "unit-ready",
  },
  {
    name: "Incident Report",
    to: "/finance/reports/incident-report",
    icon: "agent-icon-2",
    value: "incident-report",
  },
  {
    name: "Fit Out Report",
    to: "/finance/reports/fitout-report",
    icon: "agent-icon-2",
    value: "fitout-report",
  },
  {
    name: "AMC Contract Report",
    to: "/finance/reports/amc-report",
    icon: "agent-icon-2",
    value: "amc-report",
  },
  {
    name: "Facility Contract Report",
    to: "/finance/reports/facility-contract-report",
    icon: "agent-icon-2",
    value: "facility-contract-report",
  },
  {
    name: "Utility Contract Report",
    to: "/finance/reports/utility-contract",
    icon: "agent-icon-2",
    value: "utility-contract",
  },
  {
    name: "Bank Account Statement Report",
    to: "/finance/reports/bank-account-statement",
    icon: "agent-icon-2",
    value: "utility-contract-report",
  },
  {
    name: "Collection Report",
    to: "/finance/reports/collection-report",
    icon: "agent-icon-2",
    value: "collection-report",
  },
  // {
  //   name: "Revenue Recognition Report",
  //   to: "/finance/reports/revenue-recognition-report",
  //   icon: "agent-icon-2",
  //   value: "revenue-recognition-report",
  // },
  {
    name: "Work Order Report",
    to: "/finance/reports/work-order-report",
    icon: "agent-icon-2",
    value: "work-order-report",
  },
  {
    name: "Work Order Aging Report",
    to: "/finance/reports/work-order-aging",
    icon: "agent-icon-2",
    value: "work-order-aging",
  },
  {
    name: "Planned Vs Actual PPM In House Report",
    to: "/finance/reports/ppm-report-internal",
    icon: "agent-icon-2",
    value: "ppm-report-internal",
  },
  {
    name: "Planned Vs Actual PPM Report for External Vendors",
    to: "/finance/reports/ppm-report-external",
    icon: "agent-icon-2",
    value: "ppm-report-external"
  },

  {
    name: "Tenant Report",
    to: "/finance/reports/tenant-report",
    icon: "agent-icon-2",
    value: "tenant-report"
  },

  {
    name: "Petty Cash By Building Report",
    to: "/finance/reports/petty-cash-claim-by-building",
    icon: "agent-icon-2",
    value: "petty-cash-claim-by-building"
  },
  {
    name: "Petty Cash By Supervisor Report",
    to: "/finance/reports/petty-cash-by-supervisor-report",
    icon: "agent-icon-2",
    value: "petty-cash-by-supervisor-report"
  },
  {
    name: "Petty Cash Transaction Report",
    to: "/finance/reports/petty-cash-transaction-report",
    icon: "agent-icon-2",
    value: "petty-cash-transaction-report"
  },
  {
    name: "Petty Cash Claim Report",
    to: "/finance/reports/petty-cash-claim-categories",
    icon: "agent-icon-2",
    value: "petty-cash-claim-categories"
  },
  {
    name: "Daily Transaction Detail Report",
    to: "/finance/reports/daily-transaction-detail",
    icon: "agent-icon-2",
    value: "daily-transaction-detail"
  },
  {
    name: "Supplier Report",
    to: "/finance/reports/supplier-report",
    icon: "agent-icon-2",
    value: "supplier-report"
  },
  {
    name: "Expense Report",
    to: "/finance/reports/expense-report",
    icon: "agent-icon-2",
    value: "expense-report"
  },
  {
    name: "Expense Detail Report",
    to: "/finance/reports/expense-detail-report",
    icon: "agent-icon-2",
    value: "expense-detail-report"
  },
  {
    name: "Legal Case Report",
    to: "/finance/reports/legal-case-report",
    icon: "agent-icon-2",
    value: "legal-case-report"
  },
  {
    name: "Building Cost Report",
    to: "/finance/reports/building-cost-report",
    icon: "agent-icon-2",
    value: "building-cost-report"
  },
  {
    name: "Blanket Agreement Detail Report",
    to: "/finance/reports/blanket-agreement-detail-report",
    icon: "agent-icon-2",
    value: "blanket-agreement-detail-report"
  },
  {
    name: "Building Revenue Report",
    to: "/finance/reports/building-revenue-report",
    icon: "agent-icon-2",
    value: "building-revenue-report"
  },
  {
    name: "Suppliers Advance Payments Report",
    to: "/finance/reports/supplier-advance-payments",
    icon: "agent-icon-2",
    value: "supplier-advance-payments"
  },
  {
    name: "Invoice Listing Report",
    to: "/finance/reports/invoice-listing-report",
    icon: "agent-icon-2",
    value: "invoice-listing-report"
  },
  {
    name: "Tenant Outstanding Report",
    to: "/finance/reports/tenant-outstanding",
    icon: "agent-icon-2",
    value: "tenant-outstanding"
  },
  {
    name: "Out of Scope Report",
    to: "/finance/reports/out-of-scope",
    icon: "agent-icon-2",
    value: "out-of-scope"
  },
  {
    name: "Zero Rate Purchase Report",
    to: "/finance/reports/zero-rate-purchase",
    icon: "agent-icon-2",
    value: "zero-rate-purchase"
  },
  {
    name: "Exempt Supplies Sale",
    to: "/finance/reports/exempt-sale",
    icon: "agent-icon-2",
    value: "exempt-sale"
  },
  {
    name: "Exempt Supplies Purchase",
    to: "/finance/reports/exempt-purchase",
    icon: "agent-icon-2",
    value: "exempt-purchase"
  },
  {
    name: "Contract Security Deposit",
    to: "/finance/reports/contract-security-deposit",
    icon: "agent-icon-2",
    value: "contract-security-deposit"
  },
  {
    name: "Vat Reconciliation",
    to: "/finance/reports/vat-reconciliation",
    icon: "agent-icon-2",
    value: "vat-reconciliation"
  },
  {
    name: "Approtionment",
    to: "/finance/reports/vat-input-apportionment",
    icon: "agent-icon-2",
    value: "vat-input-apportionment"
  },
  {
    name: "Revenue Recognition Details",
    to: "/finance/reports/revenue-recognition-details",
    icon: "agent-icon-2",
    value: "revenue-recognition-details"
  },
  {
    name: "Trial Balance By Components",
    to: "/finance/reports/trial-balance-component",
    icon: "agent-icon-2",
    value: "trial-balance-component"
  },
];

function ReportList() {
  const permission = useSelector(selectPermissions);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [reportsArray, setReportArray] = useState([]);

  useEffect(() => {
    const generateReportObject = (item, index) => ({
      key: index,
      reportName: { name: item.name, icon: item?.icon || "inventory-valuation-icon" },
      category: "Main Reports",
      to: item.to.replace("/finance/reports/", ""),
      value: item.value,
    });

    const filteredReportsArray = menuItems
      .map(generateReportObject)
      .filter((r) => checkIfValueExists(r.value, permission))
      .sort((a, b) => a?.reportName?.name.localeCompare(b?.reportName?.name));
    setReportArray(filteredReportsArray);
  }, []);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  function filterReports(searchText) {
    const search = searchText.toLowerCase();
    const reportData = menuItems.map((item, index) => ({
      key: index,
      reportName: { name: item.name, icon: item?.icon || "inventory-valuation-icon" },
      category: "Main Reports",
      to: item.to.replace("/finance/reports/", ""),
    }));
    return reportData.filter((report) => report.reportName.name.toLowerCase().includes(search));
  }

  const onChange = (text) => {
    setFilterText(text);
    if (!text) {
      setReportArray(
        menuItems.map((item, index) => ({
          key: index,
          reportName: { name: item.name, icon: item?.icon || "inventory-valuation-icon" },
          category: "Main Reports",
          to: item.to.replace("/finance/reports/", ""),
        }))
      );
      return;
    }
    setReportArray(filterReports(text));
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Reports" icon="coins-stacked-stroke-icon" />}
        rightContent={(
          <div>
            <Input
              className="input-height-fix"
              value={filterText}
              onChange={(e) => onChange(e)}
              placeholder="Search by name"
            />
          </div>
        )}
      />

      <Accordion title="Main reports">
        <div className="report-overview">
          {reportsArray.length > 0 ? (
            <ReportTable
              data={reportsArray}
              perPage={perPage}
              perPageOptions={perPageOptions}
              currentPage={currentPage}
              handlePagination={handlePagination}
              totalCount={1}
              activeStatusTab="Reports Overview"
            />
          ) : (
            <div className="report-no-item">
              <div className="report-no-item-container">
                <SvgIcon icon="no-report-list" />
                <div className="report-no-item-title">Report Not Found</div>
                <div className="report-no-item-subtitle">
                  The report you&apos;re looking for isn&apos;t available in our system. Please
                  check the spelling or try a different search term.
                </div>
              </div>
            </div>
          )}
        </div>
      </Accordion>
    </BoxedContent>
  );
}

export default ReportList;
