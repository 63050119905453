import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getTrialBalanceComponentColumns() {
  const colArr = [
    {
      label: "Account #",
      value: true,
      columnId: "code",
      resizable: true,
      width: estimatedColumnWidth("number"),
      formatter: (value) => String(value),
      className: "justify-content-center"
    },
    {
      label: "Account Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Component",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
    },
    {
      label: "Opening Balance",
      value: true,
      columnId: "openingBalance",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Debit",
      value: true,
      columnId: "debits",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Credit",
      value: true,
      columnId: "credits",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Net Activity",
      value: true,
      columnId: "netActivity",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Closing Balance",
      value: true,
      columnId: "closingBalance",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
  ];
  return colArr;
}
