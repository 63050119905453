import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { isEmpty, kebabCase } from "lodash";
import { addDays, differenceInDays, intervalToDuration } from "date-fns";
import toast from "react-hot-toast";
import { selectActiveCompany } from "@/store/appSlice";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import {
  bulkCreateDynamicObjectRecord,
  deleteDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import {
  calculateTax,
  getPaymentDate,
  formatDecimalValues,
  formatCurrency,
} from "@/utils/helpers";
import { prepareFieldValue } from "@/utils/dynamic/helpers";
import { useCompanyAccount } from "@/hooks";

const preparePaymentDetail = (formState, company) => {
  const { detail, noOfPayments, agreementStartDate, agreementEndDate } =
    formState;

  if (!noOfPayments) return;

  const paymentDetail = [];

  detail.forEach((item) => {
    const amount = formatDecimalValues(Number(item.amount / noOfPayments));
    const taxAmount = formatDecimalValues(
      Number(item.taxAmount / noOfPayments)
    );
    const totalAmount = formatDecimalValues(
      Number(item.totalAmount / noOfPayments)
    );

    for (let i = 0; i < noOfPayments; i += 1) {
      paymentDetail.push({
        company: {
          label: company.name,
          value: company.id,
        },
        paymentNumber: i + 1,
        paymentDate: getPaymentDate({
          agreementStartDate,
          agreementEndDate,
          index: i,
          noOfPayments,
        }),
        asset: item.asset,
        account: item.account,
        amount,
        tax: item.tax,
        taxAmount,
        totalAmount,
        paymentStatus: {
          label: "Draft",
          value: "Draft",
        },
        description: `Payment no. ${i + 1} for expense for ${
          item?.asset?.label
        }`,
      });
    }
  });

  return paymentDetail;
};

function BlanketAgreementForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef(null);
  const { id } = useParams();
  const [state, setState] = useState({});
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });
  const isEditing = Boolean(id);
  const queryClient = useQueryClient();

  const { data: schema } = useQuery(
    [
      "dynamic-object-camelized-schema",
      dynamicObjectMap.get("BlanketAgreementPaymentDetailObjectName"),
    ],
    () =>
      getDynamicObjectByNameWithCamelizedFieldNames(
        dynamicObjectMap.get("BlanketAgreementPaymentDetailObjectName")
      ),
    {
      enabled: isEditing,
    }
  );

  const saveBulkMutation = useMutation(({ objectName, dataObjects }) =>
    bulkCreateDynamicObjectRecord(objectName, dataObjects)
  );

  const deleteMutation = useMutation(({ key, objectName }) =>
    deleteDynamicObjectRecord(objectName, key)
  );

  useEffect(() => {
    if (defaultAccounts && !state.areAccountsSelected) {
      const { serviceContractClearing } = defaultAccounts;

      setState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        clearingAccount: serviceContractClearing,
      }));
    }
  }, [defaultAccounts]);

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  useEffect(() => {
    if (taxRulesData && !id) {
      const taxItem = taxRulesData?.data.find(
        (i) =>
          i.name === "Input VAT Partial-recoverable - Residential & Admin (5%)"
      );
      if (!taxItem) return;
      taxItem.label = taxItem?.name;
      taxItem.value = taxItem?.id;
      const initialState = {
        tax: taxItem,
        detailTable: {
          tax: taxItem,
        },
      };
      setState((prevState) => ({
        ...prevState,
        ...initialState,
      }));
    }
  }, [taxRulesData]);

  const setDetailItemData = (
    key,
    item,
    tax,
    amountOfTax,
    account,
    diffInDays,
    agreementEndDate,
    agreementStartDate
  ) => {
    if (!item) return item;

    const { amount } = item;
    const lineItemTax = key === "tax" ? tax : item.tax;

    if (amount) {
      const { years, months, days } = intervalToDuration({
        start: new Date(agreementStartDate),
        end: addDays(new Date(agreementEndDate), 1),
      });

      if (!months && !days && years) {
        item.annualAmount = formatDecimalValues(Number(amount / years));
      } else {
        item.annualAmount = formatDecimalValues(
          Number(amount * (1 / (diffInDays / 365)))
        );
      }
    } else {
      item.annualAmount = 0;
    }

    if (!lineItemTax) return item;
    item.tax = lineItemTax;

    if (amountOfTax && lineItemTax) {
      const parameters = {
        amount: amount || 0,
        amountOfTax,
        tax: lineItemTax,
        taxAmount: "",
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      item.taxAmount = taxAmount;
      item.amountBeforeTax = principalAmount || 0;
    }

    item.totalAmount = Number(item.amountBeforeTax) + Number(item.taxAmount);
    item.account = account;
    return item;
  };

  const getExpenseAccount = (formState) => {
    const { agreementMethod, category, expenseType } = formState;

    let account = null;

    if (agreementMethod?.value === "GenericExpense" && expenseType) {
      const { expenseAccount } = expenseType;

      account = {
        label: expenseAccount.name,
        value: expenseAccount.id,
      };

      return account;
    }

    if (category) {
      const { expenseAccount } = category;

      account = {
        label: expenseAccount.name,
        value: expenseAccount.id,
      };
    }

    return account;
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      tax,
      amountOfTax,
      detail,
      detailTable,
      paymentDetail,
      agreementEndDate,
      agreementStartDate,
    } = formState;

    if (!agreementStartDate || !agreementEndDate) {
      return;
    }

    const account = getExpenseAccount(formState);
    const diffInDays = differenceInDays(
      new Date(agreementEndDate),
      new Date(agreementStartDate)
    );

    const updatedDetail = detail?.map((item) =>
      setDetailItemData(
        key,
        item,
        tax,
        amountOfTax,
        account,
        diffInDays,
        agreementEndDate,
        agreementStartDate
      )
    );

    const updatedDetailTable = setDetailItemData(
      key,
      detailTable,
      tax,
      amountOfTax,
      account,
      diffInDays,
      agreementEndDate,
      agreementStartDate
    );

    const updatedPaymentDetail = paymentDetail?.map((item) =>
      setDetailItemData(
        key,
        item,
        tax,
        amountOfTax,
        account,
        diffInDays,
        agreementEndDate,
        agreementStartDate
      )
    );

    const subtotal = updatedDetail?.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.amount),
      0
    );

    if (!subtotal) {
      return;
    }

    const taxAmount = updatedDetail?.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.taxAmount),
      0
    );

    const totalAmount = Number(subtotal || 0) + Number(taxAmount || 0);

    const { years, months, days } = intervalToDuration({
      start: new Date(agreementStartDate),
      end: addDays(new Date(agreementEndDate), 1),
    });

    let annualAmount = 0;

    if (!months && !days && years) {
      annualAmount = formatDecimalValues(Number(subtotal / years));
    } else {
      annualAmount = formatDecimalValues(
        Number(subtotal * (1 / (diffInDays / 365)))
      );
    }

    const data = {
      detail: updatedDetail,
      detailTable: updatedDetailTable,
      paymentDetail: updatedPaymentDetail,
      subtotal,
      annualAmount,
      taxAmount,
      total: totalAmount,
    };

    ref.current.setFormState(data);
  };

  const setPaymentDetailTotal = (key, value) => {
    const formState = ref.current.getState();
    const updatedPaymentDetail = value.map((item) => ({
      ...item,
      totalAmount: Number(item.amount) + Number(item.taxAmount),
    }));
    formState[key] = updatedPaymentDetail;

    const data = {
      ...formState,
    };

    ref.current.setFormState(data);
  };

  const setSupplierData = (value) => {
    if (value) {
      const { paymentTerm } = value;

      if (paymentTerm && !isEmpty(paymentTerm)) {
        ref.current.setFormValue("paymentTerm", {
          label: paymentTerm.name,
          value: paymentTerm.id,
        });
      }
      ref.current.setFormValue("tRN", value?.tRN);
    } else {
      ref.current.setFormValue("tRN", "");
    }
  };

  const setPaymentDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const paymentDetail = preparePaymentDetail(formState, activeCompany);
    ref.current.setFormValue("paymentDetail", paymentDetail);
  };

  const updatePaymentDetail = async (key, value) => {
    const toastId = toast.loading("Generating Payments...");
    const formState = ref.current.getState();
    formState[key] = value;
    let newPaymentDetail = preparePaymentDetail(formState, activeCompany);
    const { paymentDetail } = formState;
    const dataObjects = [];

    try {
      newPaymentDetail.forEach((payment) => {
        const dataObject = {};

        schema?.document.forEach((field) => {
          dataObject[field.name] = prepareFieldValue(field, payment);
        });

        dataObject.detailId = id;

        dataObjects.push(dataObject);
      });

      paymentDetail.forEach((payment) => {
        deleteMutation.mutate({
          objectName: dynamicObjectMap.get(
            "BlanketAgreementPaymentDetailObjectName"
          ),
          key: payment.id,
        });
      });

      const response = await saveBulkMutation.mutateAsync({
        objectName: dynamicObjectMap.get(
          "BlanketAgreementPaymentDetailObjectName"
        ),
        dataObjects,
      });

      newPaymentDetail = newPaymentDetail.map((payment, i) => {
        payment.id = response.data[i].id;

        return payment;
      });
    } catch (error) {
      toast.error("Could not generate payments. Try again!", {
        id: toastId,
      });
    }

    toast.success("Payments generated!", {
      id: toastId,
    });

    queryClient.invalidateQueries({
      queryKey: [
        kebabCase(
          dynamicObjectMap.get("BlanketAgreementPaymentDetailObjectName")
        ),
      ],
    });

    ref.current.setFormValue("paymentDetail", newPaymentDetail);
  };

  const generatePayments = (key, value) => {
    if (isEditing) {
      updatePaymentDetail(key, value);
      return;
    }

    setPaymentDetail(key, value);
  };

  const setStatus = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { agreementMethod } = formState;

    if (agreementMethod && agreementMethod.value === "GenericExpense") {
      ref.current.setFormValue("status", {
        label: "Active",
        value: "Active",
      });
    } else {
      ref.current.setFormValue("status", {
        label: "Open",
        value: "Open",
      });
    }
  };

  const setExpenseAccount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { detailTable, detail, paymentDetailTable, paymentDetail } =
      formState;

    const account = getExpenseAccount(formState);

    const data = {};

    if (detailTable) {
      data.detailTable = {
        ...detailTable,
        account,
      };
    }

    if (detail && detail.length) {
      data.detail = detail.map((d) => ({
        ...d,
        account,
      }));
    }

    if (paymentDetailTable) {
      data.paymentDetailTable = {
        ...paymentDetailTable,
        account,
      };
    }

    if (paymentDetail && paymentDetail.length) {
      data.paymentDetail = paymentDetail.map((d) => ({
        ...d,
        account,
      }));
    }

    ref.current.setFormState(data);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "supplier":
        setSupplierData(value);
        break;

      case "category":
      case "expenseType":
        setExpenseAccount(key, value);
        break;

      case "detail":
      case "detailTable":
      case "amountOfTax":
      case "tax":
        setTaxAndTotal(key, value);
        break;

      case "noOfPayments":
        generatePayments(key, value);
        break;

      case "agreementMethod":
        setStatus(key, value);
        break;

      case "paymentDetail":
        setPaymentDetailTotal(key, value);
        break;

      default:
        break;
    }
  };

  const onBeforeSave = () => {
    const formState = ref.current.getState();
    const paymentDetail = formState?.paymentDetail;
    const totalAmount = paymentDetail.reduce(
      (sum, item) => parseFloat(sum) + parseFloat(item.totalAmount || 0),
      0
    );

    const difference = Math.abs(
      Math.round(totalAmount || 0) - Math.round(formState?.total || 0)
    );
    if (difference !== 0) {
      toast.error(
        `Payment Detail's Total Amount is not equal to the total amount. Difference ${formatCurrency(
          difference
        )}`
      );
      return false;
    }
    return true;
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        initialData={state}
        ref={ref}
        objectName={dynamicObjectMap.get("BlanketAgreementObjectName")}
        showHeader
        onStateChange={onStateChange}
        onBeforeSave={onBeforeSave}
      />
    </BoxedContent>
  );
}

export default BlanketAgreementForm;
