import { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import { getFullName, calculateTax } from "@/utils/helpers";
import { calculateContractRefund } from "@/utils/leasing/helpers";
import { useCompanyAccount } from "@/hooks";

import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";

const formatVisualInspection = (visualInspection) => {
  const data = {
    chargeItem: [],
  };

  if (visualInspection.defect && visualInspection.defect.length) {
    visualInspection.defect.forEach((defect) => {
      const item = {
        type: {
          label: "Damage Penalty",
          value: "DamagePenalty",
        },
        account: null,
        amount: defect.totalAmount,
        tax: null,
        taxAmount: "",
        totalAmount: defect.totalAmount,
      };

      data.chargeItem.push(item);
    });
  }

  return data;
};

const formatContract = (contract) => ({
  tenant: {
    ...contract.tenant,
    label: getFullName(contract.tenant),
    value: contract.tenant.id,
  },
  contract: {
    label: contract.number,
    value: contract.id,
    agreementStartDate: contract.agreementStartDate,
    agreementEndDate: contract.agreementEndDate,
    contractTerminationDate: contract.contractTerminationDate,
    securityDeposit: contract.securityDeposit,
    earlyTerminationPenalty: contract.earlyTerminationPenalty,
  },
  rentForPeriod: contract.totalContractAmount,
  utilityAmountForPeriod: contract.totalUtilityCharge,
});

const calculateContractCharges = (contract, visualInspectionData) => {
  const { damagePenaltyAccount } = contract;

  const data = {
    chargeItem: [],
    totalCharge: 0,
  };

  if (visualInspectionData) {
    const formattedVisualInspection =
      formatVisualInspection(visualInspectionData);
    data.chargeItem = data.chargeItem.concat(
      formattedVisualInspection.chargeItem
    );
    data.chargeItem = data.chargeItem.map((c) => ({
      ...c,
      account: {
        label: damagePenaltyAccount.name,
        value: damagePenaltyAccount.id,
      },
    }));
  }

  data.totalCharge = data.chargeItem.reduce(
    (prevValue, { totalAmount }) => prevValue + totalAmount,
    0
  );

  return data;
};

function ContractTerminationForm() {
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState({});
  const { redirect } = useTaskRedirect();
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });

  const visualInspection = searchParams.get("visualInspection");
  const moveOutRequest = searchParams.get("moveOutRequest");

  const { data: visualInspectionData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("VisualInspectionObjectName")),
      visualInspection,
      moveOutRequest,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("VisualInspectionObjectName")),
        visualInspection
      ),
    {
      enabled: Boolean(visualInspection),
    }
  );

  const { data: moveOutRequestData, isFetched: isMoveOutRequestFetched } =
    useQuery(
      [
        kebabCase(dynamicObjectMap.get("MoveOutRequestObjectName")),
        moveOutRequest,
      ],
      () =>
        getDynamicObjectRecordById(
          kebabCase(dynamicObjectMap.get("MoveOutRequestObjectName")),
          moveOutRequest
        ),
      {
        enabled: Boolean(moveOutRequest),
      }
    );

  const contractId = moveOutRequestData?.contract?.id;

  const { data: contractData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("ContractObjectName")),
      "move-out-request",
      contractId,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ContractObjectName")),
        contractId
      ),
    {
      enabled: Boolean(isMoveOutRequestFetched) && Boolean(contractId),
    }
  );

  const { data: contractDetail } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("ContractObjectName")),
      "contract-detail",
      state.contract?.value,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ContractObjectName")),
        state?.contract?.value
      ),
    {
      enabled: Boolean(!visualInspection) && Boolean(state?.contract?.value),
    }
  );

  useEffect(() => {
    if (contractData) {
      const formattedContract = formatContract(contractData);
      setInitialState((prevState) => ({
        ...prevState,
        ...formattedContract,
      }));
      // eslint-disable-next-line no-use-before-define
      setRentDetails();
    }
  }, [contractData]);

  const setRentDetails = () => {
    const moveOutDate = new Date(moveOutRequestData?.mODate);

    let contract;

    if (visualInspection && contractData) {
      contract = contractData;
    }

    if (!visualInspection) {
      contract = contractDetail;
    }

    const chargesData = calculateContractCharges(
      contract,
      visualInspectionData
    );
    const refundData = calculateContractRefund(
      contract,
      moveOutDate,
      chargesData.totalCharge
    );

    setInitialState((prevState) => ({
      ...prevState,
      ...refundData,
      ...chargesData,
    }));
  };

  const setTotalCharge = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const { chargeItem, amountOfTax, balanceAmount } = formState;

    const data = {};

    const updatedChargeItem = chargeItem.map((item) => {
      let { amount } = item;
      const { tax, taxAmount: prevTaxAmount } = item;
      let taxAmount = 0;

      if (tax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax,
          taxAmount: "",
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } =
          calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      return {
        ...item,
        amount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    data.chargeItem = updatedChargeItem;

    data.totalCharge = updatedChargeItem.reduce(
      (prevValue, { totalAmount }) => prevValue + totalAmount,
      0
    );

    data.totalRefundAmount = balanceAmount - data.totalCharge;

    ref.current.setFormState(data);
  };

  const setBalanceAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      rentForStayedPeriod,
      totalRentReceived,
      rentVatForStayedPeriod,
      totalRentVatReceived,
      utilityAmountForStayedPeriod,
      totalUtilityAmountReceived,
      utilityVatAmountForStayedPeriod,
      totalUtilityVatAmountReceived,
      totalCharge,
    } = formState;

    let contract;

    if (visualInspection && contractData) {
      contract = contractData;
    }

    if (!visualInspection) {
      contract = contractDetail;
    }

    const { securityDeposit } = contract;

    const rentRefund = Number(totalRentReceived) - Number(rentForStayedPeriod);
    const rentVatRefund =
      Number(totalRentVatReceived) - Number(rentVatForStayedPeriod);
    const utilityAmountRefund =
      Number(totalUtilityAmountReceived) - Number(utilityAmountForStayedPeriod);
    const utilityVatAmountRefund =
      Number(totalUtilityVatAmountReceived) -
      Number(utilityVatAmountForStayedPeriod);

    const balanceAmount =
      rentRefund + utilityAmountRefund + Number(securityDeposit || 0);
    const totalRefundAmount = balanceAmount - Number(totalCharge || 0);
    const totalRefundVatAmount = rentVatRefund + utilityVatAmountRefund;

    ref.current.setFormState({
      rentRefund,
      rentVatRefund,
      utilityAmountRefund,
      utilityVatAmountRefund,
      balanceAmount,
      totalRefundAmount,
      totalRefundVatAmount,
    });
  };

  const setEarlyTerminationPenalty = (key, value) => {
    if (!value) return;

    const formState = ref.current.getState();
    const { chargeItem, totalCharge } = formState;

    // Do not add early termination charge if already added
    if (chargeItem && chargeItem.length) {
      const earlyTerminationItem = chargeItem.find(
        (c) => c.type.value === "EarlyTermination"
      );
      if (earlyTerminationItem) {
        return;
      }
    }

    const { earlyTerminationPenalty, contractTerminationAccount } =
      contractData;

    const earlyTerminationCharge = {
      type: {
        label: "Early Termination",
        value: "EarlyTermination",
      },
      account: {
        label: contractTerminationAccount.name,
        value: contractTerminationAccount.id,
      },
      amount: earlyTerminationPenalty ?? 500,
      tax: null,
      taxAmount: 0,
      totalAmount: earlyTerminationPenalty ?? 500,
    };

    const data = {};

    if (!chargeItem || !chargeItem.length) {
      data.chargeItem = [earlyTerminationCharge];
      data.totalCharge = earlyTerminationPenalty;
    }

    if (chargeItem && chargeItem.length) {
      data.chargeItem = [...chargeItem, earlyTerminationCharge];
      data.totalCharge = Number(totalCharge) + earlyTerminationPenalty;
    }

    ref.current.setFormState(data);
    setBalanceAmount("totalCharge", data.totalCharge);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "contract":
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        break;

      case "addEarlyTerminationPenalty":
        setEarlyTerminationPenalty(key, value);
        break;

      case "chargeItem":
        setTotalCharge(key, value);
        break;

      case "rentForStayedPeriod":
      case "rentVatForStayedPeriod":
      case "utilityAmountForStayedPeriod":
      case "utilityVatAmountForStayedPeriod":
        setBalanceAmount(key, value);
        break;

      default:
        break;
    }
  };

  const onChildStateChange = ({
    index,
    key,
    value,
    parentField,
    parentFieldType,
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "chargeItem") {
      switch (key) {
        case "type": {
          parentFieldState[key] = value;
          const { type } = parentFieldState;
          const { contractBreakPenalty, otherIncome, damageBreakPenalty } =
            defaultAccounts;

          let account = otherIncome;

          if (type?.value === "EarlyTermination") {
            account = contractBreakPenalty;
          }

          if (type?.value === "DamagePenalty") {
            account = damageBreakPenalty;
          }

          parentFieldState.account = account;

          break;
        }

        default:
          break;
      }
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        objectName={dynamicObjectMap.get("ContractTerminationObjectName")}
        showHeader
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
        readOnlyFields={["moveOutDate"]}
      />
    </BoxedContent>
  );
}

export default ContractTerminationForm;
