import { getSupplierAgingRows, getSupplierAgingColumns } from "./supplier-aging-statement";
import { getConsolidatedSupplierAging } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const vatDetailFilters = {
  Type: "All",
};

const vatDetailFilterOptions = [
  { key: "type", apiKey: "Type" },
];

export default function ReportSupplierAging({ activeCompany }) {
  return (
    <DynamicReport
      reportName="supplier-aging"
      activeCompany={activeCompany}
      columnsDataProvider={getSupplierAgingColumns}
      rowsDataProvider={getSupplierAgingRows}
      fetchData={getConsolidatedSupplierAging}
      exportUrl="reports/export-query-report/consolidated-supplier-aging-report"
      filtersInitialState={vatDetailFilters}
      filterOptions={vatDetailFilterOptions}
      orderBy="SupplierName"
      showDateFilter
      showDateRangeFilter={false}
    />
  );
}
