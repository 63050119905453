import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ReportBankAccountStatement
} from "@/components/finance/reporting";
import {
  BoxedContent, Header, HeaderLeftContent
} from "@/components/common";
import { selectActiveCompany } from "@/store/appSlice";

function ReportBankAccountStatementWrapper() {
  const { id } = useParams();
  const activeCompany = useSelector(selectActiveCompany);

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Bank Account Statement" subtitle="" icon="file-check-stroke-icon" />}
      />
      <ReportBankAccountStatement id={id} activeCompany={activeCompany} />
    </BoxedContent>
  );
}

export default ReportBankAccountStatementWrapper;
